import { useDispatch } from 'react-redux';
import fm from 'format-message';
import useAccountQuery from 'queries/useAccountQuery';
import Link from '@activebrands/core-web/components/Link';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import { signOut } from '@activebrands/core-web/state/auth/actions';
import LogOutIcon from 'components/icons/LogOutIcon';
import SweetProtectionIcon from 'components/icons/SweetProtectionIcon';
import OverlayHeader from 'components/overlays/OverlayHeader';
import Paragraph from 'components/text/Paragraph';

const Wrapper = styled('div', {
    position: 'relative',
    width: '100vw',
    color: 'var(--color-text-account)',
    height: 'calc(100vh - var(--height-header) - 41px)',
    backgroundColor: 'var(--color-bg)',
});

const Icon = styled('img', {
    marginRight: '8px',
    height: '16px',
    width: '16px',
});

const InnerWrapper = styled('div', {
    zIndex: 'var(--zindex-header)',
    height: '100%',
});

const NavigationWrapper = styled('div', {
    width: '100%',
    height: '100%',
    zIndex: 'calc(var(--zindex-header)',
});

const Navigation = styled('div', {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: 'var(--color-bg)',
});

const Heading = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 8px',
});

const InternalMenu = styled('div', {
    height: '36px',
    width: '100%',
    borderRadius: '40px',
    marginBottom: '10px',
});

const NavigationOverlay = () => {
    const [css] = useStyletron();
    const { internalMenu, externalMenu } = useAccountQuery();
    const activeClass = css({
        color: 'var(--color-base-brand-grey-dark)',
    });
    const dispatch = useDispatch();

    return (
        <Wrapper>
            <InnerWrapper>
                <div
                    className={css({
                        borderBottom: '1px solid var(--color-border-account-shortcuts)',
                    })}
                >
                    <Heading>
                        <Paragraph as="p" fontKeys="Misc/14_110_-0175">
                            {fm('Account')}
                        </Paragraph>
                        <SweetProtectionIcon width="80px" />
                        <OverlayHeader overlayId="account-navigation" />
                    </Heading>
                </div>
                <NavigationWrapper>
                    <Navigation>
                        <div>
                            <ul className={css({ gap: '16px', padding: '20px 12px 0 12px' })}>
                                {internalMenu &&
                                    internalMenu.map((internalMenuItem, index) => (
                                        <InternalMenu key={index}>
                                            <Link
                                                activeClassName={activeClass}
                                                className={css({
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    height: '100%',
                                                })}
                                                rel="nofollow"
                                                to={internalMenuItem.link.url}
                                            >
                                                <Paragraph
                                                    $style={{ whiteSpace: 'noWrap' }}
                                                    as="p"
                                                    fontKeys="Primary/32_-04"
                                                >
                                                    {internalMenuItem.label}
                                                </Paragraph>
                                            </Link>
                                        </InternalMenu>
                                    ))}
                            </ul>
                        </div>
                        <div>
                            <ul>
                                {externalMenu &&
                                    externalMenu.map((externalMenuItem, index) => {
                                        const icon = externalMenuItem.icon[0]?.icon[0]?.filename;

                                        return (
                                            <li
                                                className={css({
                                                    height: '36px',
                                                    width: '100%',
                                                    borderRadius: '40px',
                                                    padding: '0px 12px',
                                                })}
                                                key={index}
                                            >
                                                <Link
                                                    activeClassName={activeClass}
                                                    className={css({
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        width: '100%',
                                                        height: '100%',
                                                    })}
                                                    rel="nofollow"
                                                    to={externalMenuItem.link.url}
                                                >
                                                    {icon && <Icon src={icon} />}
                                                    <Paragraph as="p" fontKeys="Regular-20/100">
                                                        {externalMenuItem.label}
                                                    </Paragraph>
                                                </Link>
                                            </li>
                                        );
                                    })}
                                <li
                                    className={css({
                                        height: '36px',
                                        width: '100%',
                                        padding: '12px',
                                        marginTop: '12px',
                                    })}
                                >
                                    <Link
                                        activeClassName={activeClass}
                                        className={css({
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                            height: '100%',
                                            cursor: 'pointer',
                                        })}
                                        rel="nofollow"
                                        to="/"
                                        onClick={() => dispatch(signOut())}
                                    >
                                        <LogOutIcon $style={{ marginRight: '8px' }} />
                                        <Paragraph as="p" fontKeys="Regular-20/100">
                                            {fm('Log out')}
                                        </Paragraph>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </Navigation>
                </NavigationWrapper>
            </InnerWrapper>
        </Wrapper>
    );
};

export default NavigationOverlay;
